import Vue from 'vue'
import VueRouter from 'vue-router'
import AppLayout from "@/layout/AppLayout.vue"

Vue.use(VueRouter)

const routes = [
	{
		path: "*",
		redirect: "/refund/dashboard",
	},
	{
		path: "/refund",
		component: AppLayout,
		children: [{
			path: "rules",
			name: "Rules",
			component: () => import( /* webpackChunkName: "Login" */ '../views/Rules.vue'),
			meta: {
				requiresTerms: false,
				hasClose: true,
				closeTo: "rules-use",
				hasBack: false,
				hasQuestion: false,
			}
		},
		{
			path: "rules-2",
			name: "RulesTwo",
			component: () => import( /* webpackChunkName: "Login" */ '../views/Rules-2.vue'),
			meta: {
				requiresTerms: false,
				hasClose: true,
				closeTo: "dashboard",
				hasBack: false,
				hasQuestion: false,
			}
		},
		{
			path: "rules-use",
			name: "RulesUse",
			component: () => import( /* webpackChunkName: "Login" */ '../views/RulesUse.vue'),
			meta: {
				requiresTerms: false,
				hasClose: false,
				closeTo: "rules",
				hasBack: false,
				hasQuestion: false,
			}
		},
		{
			path: 'dashboard',
			name: 'Dashboard',
			component: () => import( /* webpackChunkName: "NewRefund" */ '../views/Dashboard.vue'),
			meta: {
				requiresTerms: true,
				hasClose: false,
				hasBack: false,
				hasQuestion: true,
				to: ''
			}
		},
		{
			path: 'new-warning',
			name: 'NewRefundWarning',
			component: () => import( /* webpackChunkName: "NewRefund" */ '../views/NewRefundWarning.vue'),
			meta: {
				requiresTerms: true,
				hasClose: false,
				hasBack: true,
				hasQuestion: false,
				to: 'dashboard'
			}
		},
		{
			path: 'new-form',
			name: 'NewRefundForm',
			component: () => import( /* webpackChunkName: "NewRefund" */ '../views/NewRefundForm.vue'),
			meta: {
				requiresTerms: true,
				hasClose: false,
				hasBack: true,
				to: 'dashboard'
			}
		},
		],
	},
	// {
	// 	path: '/new-refund',
	// 	name: 'NewRefund',
	// 	component: () => import( /* webpackChunkName: "NewRefund" */ '../views/NewRefund.vue')
	// },
	{
		path: '/consult-refund',
		name: 'ConsultRefund',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "NewRefund" */ '../views/ConsultRefund.vue')
	}
]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresTerms)) {
		if (localStorage.getItem('read_the_terms') == null) {
			next({
				path: '/refund/rules',
				params: {
					nextUrl: to.fullPath
				}
			})
		} else {
			let user = JSON.parse(localStorage.getItem('user'))
			if (to.matched.some(record => record.meta.is_admin)) {
				if (user.is_admin == 1) {
					next()
				} else {
					next({
						name: 'Dashboard'
					})
				}
			} else {
				next()
			}
		}
	} else {
		next()
	}
})


export default router
