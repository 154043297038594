import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import { getUploadLink, validateInvoice, getInvoces, getLastInvoice } from '@/services/general.services';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		uploadLinkStatus: 'init',
		consultStatus: 'init',
		invoiceList: [],
		lastRequest: {},
	},
	getters: {
		uploadLinkStatus(state) {
			return state.uploadLinkStatus
		},
		consultStatus(state) {
			return state.consultStatus
		},
		getInvoice(state) {
			return state.invoiceList
		},
		getLastRequest(state) {
			return state.lastRequest
		}
	},
	mutations: {
		UPLOAD_LINK_STATUS(state, value) {
			state.uploadLinkStatus = value
		},
		CONSULT_STATUS(state, value) {
			state.consultStatus = value
		},
		SET_INVOICES(state, value) {
			state.invoiceList = value
		},
		SET_LAST_REQUEST(state, value) {
			state.lastRequest = value
		}
	},
	actions: {
		async fetchUploadLink({ commit }, params) {
			commit('UPLOAD_LINK_STATUS', 'pending')
			try {
				const { data } = await getUploadLink(params)
				commit('UPLOAD_LINK_STATUS', 'init')
				return data
			} catch (error) {
				commit('UPLOAD_LINK_STATUS', 'init')
				throw error
			}
		},
		async sendPhoto({ commit }, params) {
			commit('UPLOAD_LINK_STATUS', 'pending')
			try {
				// const { data } = await axios({ url: params.url, formDat: params.formData })
				const data = await axios({ method: 'PUT', url: params.url, data: params.formData, headers: {'Content-Type': 'image/jpeg' } })
				commit('UPLOAD_LINK_STATUS', 'init')
				return data
			} catch (error) {
				commit('UPLOAD_LINK_STATUS', 'init')
				throw error
			}
		},
		async validateInvoice({ commit }, params) {
			commit('UPLOAD_LINK_STATUS', 'pending')
			try {
				const { data } = await validateInvoice({id: params.id, payload: params.payload})
				commit('UPLOAD_LINK_STATUS', 'init')
				return data
			} catch (error) {
				commit('UPLOAD_LINK_STATUS', 'init')
				throw error
			}
		},
		async consultRefund({ commit }, params) {
			console.log(params)
			commit('CONSULT_STATUS', 'pending')
			try {
				const { data } = await getInvoces(params)
				commit('CONSULT_STATUS', 'init')
				commit('SET_INVOICES', data)
				return data
			} catch (error) {
				commit('CONSULT_STATUS', 'init')
				throw error
			}
		},
		async consultLastRefund({ commit }, params) {
			try {
				const { data } = await getLastInvoice(params)
				commit('SET_LAST_REQUEST', data)
				return data
			} catch (error) {
				throw error
			}
		},
	},
	modules: {}
})