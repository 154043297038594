<template>
  <div class="h-100">
    <div class="pb-13">
      <h1 class="text-center mb-4">Regulamento</h1>

      <p dir="ltr">
        Regulamento Referente ao Reembolso para Compra de Álcool em Gel e Máscaras de
        proteção para Entregadores Parceiros
      </p>
      <p dir="ltr">REGULAMENTO– ÁLCOOL EM GEL E MÁSCARAS PARA ENTREGADORES</p>
      <p dir="ltr">
        Por meio deste Regulamento, IFOOD.COM AGÊNCIA DE RESTAURANTES ONLINE S.A., com
        sede na cidade de Osasco, Estado de São Paulo, na Avenida dos Autonomistas, nº
        1496, Vila Yara, CEP 06020-902, inscrita no CNPJ/MF sob o nº 14.380.200/0001-21,
        (“iFood”), estabelece os termos e condições para que o entregador parceiro possa
        realizar a solicitação de reembolso referente a compra de álcool em gel e máscaras
        .que deverão ser utilizados durante as entregas a fim de prevenir o contágio do
        vírus da COVID19, conforme os itens e as condições a seguir:
      </p>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Todos os entregadores ativos que realizaram 1 (um) pedido no mês estão
            elegíveis à solicitação de reembolso.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">O reembolso terá o valor máximo de R$18,00 (dezoito reais).</p>
        </li>
      </ol>
      <p dir="ltr" class="text-muted ml-4">
        <small
          >*A soma dos valores contemplados pode ultrapassar R$18,OO, porém o repasse será
          de no máximo R$18,OO.
        </small>
      </p>

      <ol start="3">
        <li dir="ltr">
          <p dir="ltr">Será permitido 1 (um) reembolso por entregador por mês.</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            A solicitação deverá ser realizada a partir das notas fiscais, que devem
            conter CNPJ, número, descrição dos itens e valores legíveis.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            A nota fiscal poderá conter outros itens, mas apenas álcool gel e máscaras
            serão reembolsados.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Não serão aceitas notas fiscais já utilizadas em outro mês.</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Não serão aceitas notas fiscais já utilizadas por outro entregador.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">A emissão da NF deverá ser no máximo 30 dias.</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            A solicitação poderá ser composta por mais de uma nota fiscal no mês (Exemplo:
            1 nota fiscal de máscara + 1 nota fiscal de álcool gel).
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Nota fiscal ilegível terá reembolso reprovado.</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Após solicitação, os reembolsos aprovados serão pagos no mês seguinte na conta
            cadastrada no aplicativo de entrega para pagamento de repasse. No caso de
            entregador OL, o reembolso será realizado na conta do OL gerenciador e
            repassado ao entregador, conforme fluxo já existente.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            O status da solicitação deverá ser acompanhado no aplicativo. As etapas serão:
          </p>
        </li>
      </ol>
      <p dir="ltr" class="ml-5">Solicitação em aprovação</p>
      <p dir="ltr" class="ml-5">Aprovado com pagamento previsto para o próximo mês</p>
      <p dir="ltr" class="ml-5">Pagamento concluído</p>
      <p dir="ltr" class="ml-5">Pagamento com erro, tentaremos novamente</p>
      <p dir="ltr" class="ml-5">Pagamento reprovado + motivo</p>
      <ol start="13">
        <li dir="ltr">
          <p dir="ltr">
            Os dados bancários devem estar atualizados para evitar problemas nos
            pagamentos.
          </p>
        </li>
      </ol>
    </div>
    <ifood-footer class="p-3">
      <div class="custom-checkbox">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          v-model="readTerms"
          id="read-agree"
        />
        <label class="form-check-label" for="read-agree">
          Li e concordo com o regulamento
        </label>
      </div>

      <button
        type="button"
        :disabled="!readTerms"
        class="btn btn-primary btn-lg btn-block"
        @click.prevent="continueToApp()"
      >
        Continuar
      </button>
    </ifood-footer>
  </div>
</template>

<script>
import ifoodFooter from "@/components/Footer.vue";
import amplitude from "amplitude-js";
import Faster, { FasterConfiguration } from "faster-sdk-js";
import { EventBuilder } from "faster-sdk-js";

export default {
  name: "Rules",
  components: {
    ifoodFooter,
  },
  data() {
    return {
      readTerms: false,
      driver_Id: localStorage.getItem("worker_id"),
    };
  },
  created() {
    const configuration = new FasterConfiguration(
      "c352c9f1-6120-4ef5-87e3-c5cec5eb1cda",
      "oIwfANqzIAItf97FHJulmwvN"
    );
    Faster.configure(configuration);
    const fasterAnalyticsInstance = Faster.start();
    const eventBuilder = new EventBuilder("View_CovidRefund_TermsAndConditions ", 0);
    const event_faster = eventBuilder
      .id(localStorage.getItem("id"))
      .externalId(localStorage.getItem("worker_id"))
      .dimension("driverId", localStorage.getItem("worker_id"))
      .dimension("source", "app")
      .build();
    fasterAnalyticsInstance.event.register(event_faster);
    //End
    //amplitude
    var userId = localStorage.getItem("worker_id");
    amplitude.getInstance().init("acd3720cc16f656e0f48bf417916784d", userId);
    var event = "View_CovidRefund_TermsAndConditions ";
    var eventProperties = {
      driver_Id: localStorage.getItem("worker_id"),
      driver_uuid: localStorage.getItem("id"),
      source: "app",
      timestamp: Date.now(),
    };
    amplitude.getInstance().logEvent(event, eventProperties);
  },
  methods: {
    continueToApp() {
      const configuration = new FasterConfiguration(
        "c352c9f1-6120-4ef5-87e3-c5cec5eb1cda",
        "oIwfANqzIAItf97FHJulmwvN"
      );
      Faster.configure(configuration);
      const fasterAnalyticsInstance = Faster.start();
      const eventBuilder = new EventBuilder(
        "Click_CovidRefund_TermsAndConditions_Accept ",
        0
      );
      const event_faster = eventBuilder
        .id(localStorage.getItem("id"))
        .externalId(localStorage.getItem("worker_id"))
        .dimension("driverId", localStorage.getItem("worker_id"))
        .build();
      fasterAnalyticsInstance.event.register(event_faster);
      //End
      //amplitude
      var userId = localStorage.getItem("worker_id");
      amplitude.getInstance().init("acd3720cc16f656e0f48bf417916784d", userId);
      var event = "Click_CovidRefund_TermsAndConditions_Accept ";
      var eventProperties = {
        driver_Id: localStorage.getItem("worker_id"),
        driver_uuid: localStorage.getItem("id"),
        timestamp: Date.now(),
      };
      amplitude.getInstance().logEvent(event, eventProperties);

      localStorage.setItem("read_the_terms", true);
      this.$router.push({ name: "Dashboard" });
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  background-color: $white;
}
h1 {
  font-size: 18px;
}

p {
  color: #717171;
}
</style>
