<template>
	<div class="modal-backdrop-custom">
		<div class="modal-inside">
			<slot name="body">
				This is the default body!
			</slot>

			<footer class="modal-footer-custom">
				<slot name="footer">
				</slot>
			</footer>
		</div>
	</div>
</template>

<script>
  export default {
  	name: 'Modal',
  	methods: {
  		close() {
  			this.$emit('close');
  		},
  	},
  };
</script>

<style>

@keyframes slidein {
  from {
    transform: translateX(100%);
  };

  to {
    transform: translateX(0%);
  }
}

.modal-backdrop-custom {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1 !important;
	z-index: 8 !important;
	animation-duration: 0.3s;
	animation-delay: 0s;
	animation-name: slidein;
}

.modal-inside {
	background: #FFFFFF;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
}


.modal-footer-custom {
	flex-direction: column;
	justify-content: flex-end;
}

.modal-body {
	position: relative;
	padding: 20px 10px;
}
</style>