<template>
  <input class="form-control" :placeholder="inputPlaceHolder" v-on:keyup.enter="$event.target.blur()" ref="inputRef" type="text" />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    modelValue: Number,
    options: Object,
    inputPlaceHolder: String
  },
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options);

    return { inputRef };
  },
};
</script>
