<template>
    <div class="content">
		<h1 class="text-red text-center">Consultar reembolso</h1>
        <div class="box search">
            <div class="header">
                Buscar
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                    <g id="search_black_24dp" transform="translate(-3.51 -3)">
                        <path id="Path_2" data-name="Path 2" d="M9.277,16.836h.994l.352-.34a8.188,8.188,0,1,1,.881.881l-.34.352v.994L4.874,25,3,23.126Zm7.547,0a5.66,5.66,0,1,0-5.66-5.66A5.653,5.653,0,0,0,16.824,16.836Z" transform="translate(0.51)"/>
                    </g>
                </svg>
            </div>
            <div class="input-content">
                <label class="" for="product-input">Por número da nota fiscal:</label>
                <input id="product-input" class="w-100" v-model="filter.nf" type="text" placeholder="000000...">
            </div>
            <div class="input-content">
                <label class="" for="product-month">Por período:</label>
                <!-- <input id="product-input" class="w-100" type="text" placeholder="00/00/00 - 00/00/00"  v-maska="'##/##/#### - ##/##/####'"> -->
				<select name="product-month" v-model="filter.month" class="w-100" id="product-month">
					<option value="">Selecione um mês</option>
					<option value="1">Janeiro</option>
					<option value="2">Fevereiro</option>
					<option value="3">Março</option>
					<option value="4">Abril</option>
					<option value="5">Maio</option>
					<option value="6">Junho</option>
					<option value="7">Julho</option>
					<option value="8">Agosto</option>
					<option value="9">Setembro</option>
					<option value="10">Outubro</option>
					<option value="11">Novembro</option>
					<option value="12">Dezembro</option>
				</select>
            </div>
			<div class="text-right d-flex">
				<button class="btn btn-red" @click="search()">
					Buscar
					<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
						<g id="search_black_24dp" transform="translate(-3.51 -3)">
							<path id="Path_2" data-name="Path 2" d="M9.277,16.836h.994l.352-.34a8.188,8.188,0,1,1,.881.881l-.34.352v.994L4.874,25,3,23.126Zm7.547,0a5.66,5.66,0,1,0-5.66-5.66A5.653,5.653,0,0,0,16.824,16.836Z" transform="translate(0.51)"/>
						</g>
					</svg>
				</button>
			</div>
        </div>
		<div v-for="(invoice, key) in getInvoice.items"  :key="key">
		<div class="box box-red" v-if="invoice.user_input">
			<div>
				<h2 class="text-center">Solicitação {{invoice.id}}</h2>
				<div class="row">
					<div class="col">Valor <span v-if="invoice.user_input.products">{{calculateTotal(invoice.user_input.products) | toCurrency }}</span> <span v-else>00,00</span></div>
					<div class="col">Data: {{invoice.user_input.purchaseDate}}</div>
				</div>
				Situação: {{getStatus(invoice.status)}}
			</div>
		</div>
		</div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'ConsultRefund',
	components: {
	},
	data() {
		return {
			showTest: false,
			filter: {
				month: "",
				nf: "",
			},
			form:{
				month: "",
			}
		}
	},
	computed: {
		...mapGetters({
			getInvoice: 'getInvoice'
		})
	},
	methods: {
		...mapActions({
			consultRefund: 'consultRefund'
		}),
		search(){
			this.consultRefund({month: this.filter.month, nf: this.filter.nf});
		},
		calculateTotal(products){
			let productsTotal;
			let quantityTotal = 0;
			products.forEach(product => {
				// console.log("TOTAL VALUE", product.value * product.quantity);
				let productsTotal = product.value * product.quantity;
				console.log(productsTotal)
				quantityTotal = productsTotal + quantityTotal;
			});
			// ======================================
			if(quantityTotal != NaN) console.log("NAN");
			else console.log("NOT NAN");
			// ======================================
			if(quantityTotal != NaN) return quantityTotal
			else return '00,00'
		},
		getStatus(status){
			switch (status) {
				case "hold":
					return "Em Analise"
				case "sucess":
					return "Sucesso"
				case "paid":
					return "Pago"
				case "failure":
					return "Falha"
			}
		}
	},
	async mounted(){
		try {
			this.consultRefund({month: this.filter.month, nf: this.filter.nf});
		} catch (error) {
			
		}
	}
}
</script>

<style lang="scss" scoped>

h1 {
	font-size: 1.5rem;
	margin-bottom: 20px;
}

.box{
    .header {
        border-radius: 8px 8px 0px 0px;
        background-color: $red;
        margin: -14px -14px 13px -14px;
        padding: 0.5rem;
        text-align: center;
        color: $white;
        svg {
            max-width: 17px;
            fill: $white;
            vertical-align: middle;
        }
    }
    &.search {

    }
	&-red{
		background: $red;
		color: $white;
		font-size: 1.02rem;
		font-weight: 100;
		word-spacing: 2px;
		.col {
			margin-bottom: 15px;
		}
		h2 {
			font-size: 1.5rem;
			margin-bottom: 15px;
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	.text-right.d-flex {
		justify-content: flex-end;
	}
    .input-content {
        position: relative;
        input {
            padding-right: 46px;
        }
        svg {
            position: absolute;
            right: 13px;
            bottom: 9px;
            fill: $gray-3;
        }
    }
}

</style>