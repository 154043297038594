<template>
    <div class="box">
        <div class="input-content" :class="{error: errors.has('field-product-'+product.index)}">
			<label class="" for="product-input">Produto !:</label>
			<input required id="product-input" class="w-100" :name="'field-product-'+product.index" v-model="product.name" v-validate="'required'" type="text" placeholder="Produto...">
            <span class="error" v-if="errors.has('field-product-'+product.index)">{{errors.first('field-product-'+product.index)}}</span>
		</div>

        <div class="row">
            <div class="input-content col">
                <label class="" for="unitary-value-input">Valor unitário:</label>
                <input id="unitary-value-input" class="w-100" :name="'field-unitary-'+product.key" v-model="product.unitaryValue" v-validate="'required'" v-money="money" type="text" placeholder="Produto...">
            </div>

            <div class="input-content col">
                <label class="" for="quantity-input">Quantidade:</label>
                <input required id="quantity-input" class="w-100" :name="'field-quantity-'+product.key" v-model="product.quantity" v-validate="'required'" type="number" placeholder="Produto...">
            </div>
            
        </div>

        <div class="text-right font-12">
            Valor final:
                <span class="color-red">R$ {{total}}</span>
        </div>
    </div>
</template>

<script>
export default {
	name: 'Product',
    props: ['product', 'index'],
	data() {
		return {
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: true /* doesn't work with directive */
            }
		}
	},
    computed: {
        total() {
            console.log(this.product.unitaryValue);
            return (parseFloat(this.product.unitaryValue.replace('R$ ', '').replace(',','.').replace(' ','')) * this.product.quantity).toFixed(2)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
