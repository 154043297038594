<template>
    <div id="app-layout" class="">

        <iFoodHeader />

        <!-- <router-view v-slot="{ Component }" id="router-view" class="content">
            <transition name="slide">
                <component :is="Component" />
            </transition>
        </router-view> -->

        <transition name="fade" mode="out-in">
            <router-view id="router-view" class="content"></router-view>
        </transition>

    </div>
</template>

<script>
    import iFoodHeader from "@/components/Header.vue";

    export default {

        name: "app-layout",
        props: {},
        components: {
            iFoodHeader,
        },
        data() {
            return {
            }
        },
        watch: {
		},
        methods: {},

    }
</script>

<style lang="scss">

.content {
    padding: 4rem 1rem 1rem;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
    // transform: translateX(2em);
}

.fade-enter-active, .fade-leave-active {
    transition: all 1s ease;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}


.slide-enter-to {
  position: absolute;
  right: 0;
}


.slide-enter-from {
  position: absolute;
  right: -100%;
}


.slide-leave-to {
  position: absolute;
  left: -100%;
}


.slide-leave-from {
  position: absolute;
  left: 0;
}

</style>
